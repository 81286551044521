import firebase from 'firebase'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyC7_gazQj_BNYHvjrf3KyID9QT12BRRO0k',
  authDomain: 'blazetasker-5f8a4.firebaseapp.com',
  databaseURL: 'https://blazetasker-5f8a4.firebaseio.com',
  projectId: 'blazetasker-5f8a4',
  storageBucket: 'blazetasker-5f8a4.appspot.com',
  messagingSenderId: '488761841812',
  appId: '1:488761841812:web:650e4304126c112bf5bc81',
}
firebase.initializeApp(firebaseConfig)

export const googleProvider = new firebase.auth.GoogleAuthProvider()

export default firebase
