import createPersistedReducer from "use-persisted-reducer";
import model from "../model";
import { debounce } from "../utils";

export const usePersistedTasksReducer = createPersistedReducer("state");

export const TASK_ACTIONS = {
  RENAME_TAB: "RENAME_TAB",
  LOAD_TASKS: "LOAD_TASKS", // for when pulling from api or store
  SET_TASK: "SET_TASK",
  SET_TASKS: "SET_TASKS",
  COMPLETE_TASK: "COMPLETE_TASK",
  LOGOUT_TASKS: "LOGOUT_TASKS",
  MOVE_TASK_UP: "MOVE_TASK_UP",
  MOVE_TASK_DOWN: "MOVE_TASK_DOWN",
};

const nonEmptyStringFilter = (x) => x !== "";

function reorder(tasks) {
  const result = {
  };
  const keys = Object.keys(tasks);
  const values = Object.values(tasks);
  const filteredValues = values.filter(nonEmptyStringFilter);
  keys.forEach((key, index) => {
    const val = filteredValues[index];
    result[key] = val || "";
  });
  return result;
}

export const tasksReducerInitialState = {
  1: {
    title: "Tab 1",
    tasks: {
      1: "Complete a task by clicking on the tick --->",
      2: "Completed tasks show up as green boxes below",
      3: "Click on Tab 2 to switch to it",
    },
  },
  2: {
    title: "Tab 2",
    tasks: {
    },
  },
  3: {
    title: "Tab 3",
    tasks: {
    },
  },
};

export const tasksReducerEmptyState = {
  1: {
    title: "Tab 1",
    tasks: {
    },
  },
  2: {
    title: "Tab 2",
    tasks: {
    },
  },
  3: {
    title: "Tab 3",
    tasks: {
    },
  },
  isEmpty: true,
};

const checkIfEmpty = (state) => {
  const allTasks = [];
  const ids = [1, 2, 3];
  ids.forEach((id) => {
    allTasks.push(...Object.values(state[id].tasks));
  });
  const hasText = allTasks.filter((x) => x.length > 0);
  return hasText.length === 0;
};

const debouncedUpdate = debounce((id, updated) => {
  model.update(id, updated);
}, 500);

export function tasksReducer(state, action) {
  const {
    payload = {
    }, type,
  } = action;
  const {
    tabId = 1, task = {
    }, tabTitle, taskId,
  } = payload;
  const { id, title } = task;
  const currentTab = state[tabId];
  const currentTasks = currentTab.tasks;

  let updatedState = {
    ...state,
  };
  state.isEmpty = checkIfEmpty(state);
  switch (type) {
    case TASK_ACTIONS.SET_TASK:// set just one task in a tab
      // TODO: send only the update
      updatedState = {
        ...state,
        [tabId]: {
          ...currentTab,
          tasks: {
            ...currentTasks, [id]: title,
          },
        },
      };
      debouncedUpdate(model.DOC_ID, updatedState);
      break;
    case TASK_ACTIONS.SET_TASKS:// set all tasks in a tab
      updatedState = {
        ...state,
        [tabId]: {
          ...currentTab, tasks: payload,
        },
      };
      break;
    case TASK_ACTIONS.COMPLETE_TASK: {
      const removed = {
        ...currentTasks, [id]: "",
      };
      const reordered = reorder(removed);
      updatedState = {
        ...state,
        [tabId]: {
          ...currentTab, tasks: reordered,
        },
      };
      model.update(model.DOC_ID, updatedState);
      break;
    }
    case TASK_ACTIONS.MOVE_TASK_UP: {
      if (taskId === "1") {
        break;
      } else {
        const currentTaskId = Number(taskId);
        const preceedingTaskId = currentTaskId - 1;
        const preceedingTask = currentTasks[preceedingTaskId];
        const currentTask = currentTasks[currentTaskId];
        const updatedTasks = {
          ...currentTasks, [preceedingTaskId]: currentTask, [currentTaskId]: preceedingTask,
        };
        updatedState = {
          ...state,
          [tabId]: {
            ...currentTab, tasks: updatedTasks,
          },
        };
      }
      break;
    }
    case TASK_ACTIONS.MOVE_TASK_DOWN: {
      if (taskId === "3") {
        break;
      } else {
        const currentTaskId = Number(taskId);
        const followingTaskId = currentTaskId + 1;
        const currentTask = currentTasks[currentTaskId];
        const followingTask = currentTasks[followingTaskId];
        const updatedTasks = {
          ...currentTasks, [followingTaskId]: currentTask, [currentTaskId]: followingTask,
        };
        updatedState = {
          ...state,
          [tabId]: {
            ...currentTab, tasks: updatedTasks,
          },
        };
      }
      break;
    }
    case TASK_ACTIONS.RENAME_TAB:
      // TODO: send only the update
      updatedState = {
        ...state,
        [tabId]: {
          ...currentTab, title: tabTitle,
        },
      };
      model.update(model.DOC_ID, updatedState);
      break;
    case TASK_ACTIONS.LOAD_TASKS:
      return {
        ...payload,
      };
    case TASK_ACTIONS.LOGOUT_TASKS:
      return tasksReducerEmptyState;
    default:
      throw new Error();
  }
  updatedState.isEmpty = checkIfEmpty(updatedState);
  return updatedState;
}
