import React from "react";
import PropTypes from "prop-types";
import "./Switch.css";

// Camel Case Formatter
String.prototype.toCamelCase = function () {
  return this.replace(/^([A-Z])|\s(\w)/g, (match, p1, p2, offset) => {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  });
};

function Switch(props) {
  const { className, label, noText, checked, onToggle } = props;
  let switchClass = className;
  const id = label.toCamelCase();

  (props.large == true ? switchClass += " switch--large" : null);
  (props.noText == true ? switchClass += " switch--no-text" : null);
  (props.theme == "success" ? switchClass += " switch--success" : null);

  return (
    <div aria-label={label} className={switchClass}>
      <label className="switch__label" htmlFor={id}>
        <input checked={checked} role="switch" type="checkbox" className="switch__input" id={id} onClick={onToggle} readOnly />
        <span className="switch__text" data-on="ON" data-off="OFF" />
        <span className="switch__handle" />
      </label>
    </div>
  );
}

Switch.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  label: PropTypes.string,
  noText: PropTypes.bool,
};

Switch.defaultProps = {
  className: "switch",
  noText: false,
};

export default Switch;
