import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
    this.state.name = props.name;
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="app-error has-text-centered">
          <h1>
            Oops! Something went wrong in '
            {this.state.name}
            . Please try logging out then logging back in.'
          </h1>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
