// we pass useEffect and useState because we need to use the same React instance of the main app

/* 
example usage as in Scratchpad, in 2 lines:
  const {usePersistedState} = buildHookUtils({useEffect,useState});
  const [userState,setUserState] = usePersistedState({});
*/

const buildHookUtils = ({ useEffect, useState }) => ({
  usePersistedState(key, defaultValue) {
    if (typeof key != 'string') {
      console.error('key passed to usePersistedState must be a string');
    }
    const [state, setState] = useState(
      () => JSON.parse(localStorage.getItem(key)) || defaultValue,
    );
    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
  },
});

export { buildHookUtils };

export default buildHookUtils;
