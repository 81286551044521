import React from "react";
import logo from "url:~/src/assets/logo.png";

function Header({ toggleViewingMode }) {
  return (
    <div id="hero">
      <img className="logo" src={logo} />
      <h1 className="title is-2" id="app-title" onDoubleClick={toggleViewingMode}>Three Things</h1>
      <small className="description">Work on only three things at any given time</small>
    </div>
  );
}

export default Header;
