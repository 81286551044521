import React from "react";
import GearIcon from "./icons/Gear";
import Header from "./Header";

function Navbar({ toggleSidePanel, toggleViewingMode }) {
  return (
    <nav id="navbar">
      <div className="left">
        <Header toggleViewingMode={toggleViewingMode} />
      </div>
      <div className="right">
        <GearIcon onClick={toggleSidePanel} />
      </div>
    </nav>
  );
}

export default Navbar;
