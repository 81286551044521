import React from "react";
import Completed from ".";
import { removeItemWithSlice } from "../../js/utils";

function CompletedContainer({ completedState, setCompleted }) {
  const removeCompletedTask = (completedId) => {
    let index = -1;
    const found = completedState.find((x, foundIndex) => {
      index = foundIndex;
      return x.id === completedId;
    });
    if (index >= 0) {
      // eslint-disable-next-line
          if (confirm(`Remove '${found.title}' from completed list ?`)) {
        const updated = removeItemWithSlice(completedState, index);
        setCompleted(updated);
      }
    }
  };
  const todayFilter = (task) => {
    const today = new Date().setHours(0, 0, 0, 0);
    const completedDay = new Date(task.completed_at).setHours(0, 0, 0, 0);
    return today === completedDay;
  };
  const completedToday = completedState.filter(todayFilter);
  return (<Completed completedState={completedToday} removeCompletedTask={removeCompletedTask} />);
}

export default CompletedContainer;
