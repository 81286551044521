import React, { useRef } from "react";

const X_OFFSET = 50;

function Task({ tabId, id, complete, val = "", setTask, inputFocus, focusOnNext, resetFocus, reorderTasks, moveTaskUp, moveTaskDown }) {
  const ref = useRef(null);
  const focusOnSelf = () => ref.current.focus();
  if (inputFocus) {
    focusOnSelf();
    resetFocus();
  }
  const onChangeHandler = ({ target: { value } }) => {
    setTask(id, value);
  };
  const notEmpty = val.length > 0;
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && notEmpty) {
      focusOnNext();
    }
  };
  const reorderArrowsOffset = reorderTasks ? 95 : 0;
  // TODO: below
  const isTopMost = id == 1;
  const isBottomMost = id == 3;
  const textAreaPaddingLeft = reorderTasks ? 110 : "inherit";
  return (
    <article className="task-group">
      <textarea
        className="task"
        rows="2"
        id={id}
        placeholder="task"
        onChange={onChangeHandler}
        value={val}
        ref={ref}
        onKeyPress={handleKeyPress}
        onDoubleClick={() => console.log(val)}
        style={{
          paddingRight: X_OFFSET, paddingLeft: textAreaPaddingLeft,
        }}
      />
      <div className="task__buttons-container-left">
        {reorderTasks && (

          <>
            <button
              type="button"
              disabled={isTopMost}
              style={{
                visibility: notEmpty ? "" : "hidden",
              }}
              className="task__btn btn__complete btn__up button"
              onClick={() => moveTaskUp(id)}
            >
              ⬆
            </button>
            <button
              type="button"
              disabled={isBottomMost}
              style={{
                visibility: notEmpty ? "" : "hidden",
              }}
              className="task__btn btn__complete btn__up button"
              onClick={() => moveTaskDown(id)}
            >
              ⬇
            </button>
          </>
        )}
      </div>
      <div className="task__buttons-container-right">
        <button
          type="button"
          style={{
            visibility: notEmpty ? "" : "hidden",
          }}
          className="task__btn btn__complete button"
          id={`button-complete-${id}`}
          onClick={() => complete({
            tabId, id,
          })}
        >
          &#10003;
        </button>
      </div>
    </article>
  );
}

export default Task;
