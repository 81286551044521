import {
  ACCEPTED_COLLECTIONS, buildModel,
} from "js/model";
import "regenerator-runtime/runtime"; // added due to Parcel error when importing model
import config from "./config";

const user = JSON.parse(localStorage.getItem("user")) || {
};

const options = {
};

if (config.IS_DEV) {
  options.dev = true;
}

const model = buildModel(ACCEPTED_COLLECTIONS.THREE_THINGS, options);
const DOC_ID = user.id;
model.getOwn = async () => await model.get(DOC_ID);
model.DOC_ID = DOC_ID;
model.listenOwn = async (cb) => await model.listenOne(DOC_ID, cb);
export default model;
