import firebase from './firebase';
const db = firebase.firestore();
import {debounce, log} from './utils';
import {COLLECTIONS as ACCEPTED_COLLECTIONS, LOG_LEVELS} from './constants';

export {ACCEPTED_COLLECTIONS}

// TODO: apply this to all write functions
function verifyOwnerIdFieldExists(data) {
  if (!data.ownerId || data.ownerId.length === 0) {
    throw Error('ownerId field must exist');
  }
}

function snapshotToDocs (snapshot) {
  return snapshot.docs.map(doc => doc.data());
}

// TODO: do this based on UTC time
function getCurrentDateString () {
  return dateToDateString(new Date());
}

/**
 * 
 * @param {Date} d 
 * @returns 
 */
function dateToDateString(d) {
  return d.toISOString().substring(0,10);
}


// maybe buildModel should take in uid so we can create here getOwn immediately
// note, for dev, include {dev: true} in options
export const buildModel = (collectionName, options) => {
  log(`buildModel called. ${JSON.stringify({collectionName,options})}`, LOG_LEVELS.DEBUG);
  if (!Object.values(ACCEPTED_COLLECTIONS).includes(collectionName)) {
    console.error('Not included'); // eslint-disable-line
  }
  let _collectionName = collectionName; // eslint-disable-line
  if (options.dev) {
    _collectionName = `dev-${collectionName}`;
  }
  const collectionRef = db.collection(_collectionName);
  const update = async (id, data) => (collectionRef.doc(id).set(data, { merge: true }));
  return ({
    add: async (data) => {
      const res = await collectionRef.add(data);
      return res && res.data && res.data()
    },
    addItemToDayDoc: async (ownerId, item) => {
      verifyOwnerIdFieldExists({ownerId});
      const dateString = getCurrentDateString();
      const id = `${ownerId}-${dateString}`;
      let currentDoc;
      try {
        const currentDocRaw = await collectionRef.doc(id).get();
        currentDoc = currentDocRaw && currentDocRaw.data();

        const newItems = [...currentDoc.items, item];
        const newDoc = { ownerId, ...currentDoc, items: newItems};
        await collectionRef.doc(id).set(newDoc);
      } catch(e) {
        console.error(e);
        // if this is a new day doc
        // const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        // create new doc
        if (!currentDoc) {
          const data = { ownerId, date: dateString, items: [ item ] };
          await collectionRef.doc(id).set(data)
        }
      }
      
    },
    // TODO: handle when doc does not exist
    get: async (id) => {
      log(`get called for id: ${id}`, LOG_LEVELS.DEBUG);
      return (await collectionRef.doc(id).get()).data()
    },
    /**
     * 
     * @param {string} id 
     * @param {number} daysN - optional Number of days
     * @returns 
     */
    getOwnLatest: async (ownerId, daysN) => {
      log(`getOwnLatest called for ownerId: ${ownerId}`, LOG_LEVELS.DEBUG);
      const snapshot = await collectionRef.orderBy("date", "desc").where("ownerId", "==", ownerId).limit(daysN || 1).get();
      return snapshotToDocs(snapshot);
    },
    listOwn: async (id) => {
      log(`listOwn called for id: ${id}`, LOG_LEVELS.DEBUG);
      return ((await collectionRef.where("ownerId", "==", id).get()).docs.map(doc => doc.data()))
    },
    // TODO: get own , get but with owner === uid
    update,
    debouncedUpdate: debounce(update, 1000),
    /*
    listenOne example
    function listenOwnAndUpdateText({text}) {
      asyncSetText(text);
    }
    model.listenOne(userState.id, listenOwnAndUpdateText);

    */
    listenOne: async (id, cb) => {
      log(`listenOne called for id: ${id}`, LOG_LEVELS.DEBUG);
      return collectionRef.doc(id).onSnapshot((doc) => cb(doc.data()))
    },
    getOwnDayDoc: async (ownerId, date) => {
      log(`getOwnDayDoc called for ownerId: ${ownerId} and date: ${date}`, LOG_LEVELS.DEBUG);
      const id = `${ownerId}-${dateToDateString(date)}`;
      const res = (await collectionRef.doc(id).get()).data();
      return res;
    },
    listenLatestDayDoc: async (ownerId, cb) => {
      log(`listenLatestDayDoc called for ownerId: ${ownerId}`, LOG_LEVELS.DEBUG);
      const id = `${ownerId}-${getCurrentDateString()}`;
      collectionRef.doc(id).onSnapshot((doc) => cb(doc.data())); // if does not yet exist, will give firebase permission error
    },
  });
};

export default buildModel
