import { SETTINGS } from "./constants";

const IS_DEV = process.env.NODE_ENV === "development";
const user = JSON.parse(localStorage.getItem("user")) || {
};
const isNazreen = user.email === "nazreen.mohamad@gmail.com";

const TOGGLEABLE_FEATURES = IS_DEV ? Object.values(SETTINGS).map((x) => x.KEY) : [SETTINGS.MULTIPLE_TABS.KEY, SETTINGS.REORDER_TASKS.KEY];

export default {
  IS_DEV,
  SHOW_AUTH: true,
  LOAD_TASKS: isNazreen,
  LISTEN_TASKS: isNazreen,
  TOGGLEABLE_FEATURES,
};
