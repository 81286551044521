import React from "react";
import Tasks from "./Tasks";
import { TASK_ACTIONS } from "../js/state/tasks";
import { VIEWING_MODES } from "../js/constants";

function TasksContainer({ ids, tasks, dispatchTasks, complete, currentTabId, viewingMode, setTaskInFocusTitle, reorderTasks, moveTaskUp, moveTaskDown }) {
  const currentTasks = tasks[currentTabId].tasks;

  const setTask = (id, title) => {
    const task = {
      id, title,
    };
    dispatchTasks({
      type: TASK_ACTIONS.SET_TASK,
      payload: {
        tabId: currentTabId, task,
      },
    });
  };
  const thisTabMoveTaskUp = (taskId) => moveTaskUp(currentTabId, taskId);
  const thisTabMoveTaskDown = (taskId) => moveTaskDown(currentTabId, taskId);
  const defaultView = (<Tasks ids={ids} setTask={setTask} complete={complete} tasks={currentTasks} tabId={currentTabId} viewingMode={viewingMode} setTaskInFocusTitle={setTaskInFocusTitle} reorderTasks={reorderTasks} moveTaskUp={thisTabMoveTaskUp} moveTaskDown={thisTabMoveTaskDown} />);

  const entries = Object.entries(tasks);

  const everythingView = (entries.map(([tabId, val]) => <Tasks ids={ids} setTask={setTask} complete={complete} tasks={val.tasks} tabId={tabId} viewingMode={viewingMode} tabTitle={val.title} key={val.title} setTaskInFocusTitle={setTaskInFocusTitle} />));

  return (
    <div className="tasks-container">
      { viewingMode === VIEWING_MODES.EVERYTHING ? everythingView : defaultView }
    </div>
  );
}

export default TasksContainer;
