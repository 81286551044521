import React, {
  useContext, useEffect, useState,
} from "react";
import { UserContext } from "./App";
import { APP_STATUSES } from "../js/constants";
import Loading from "./Loading";

function AccountInfo({ userState, logout }) {
  const [appStatus, setAppStatus] = useState(APP_STATUSES.INITIAL);
  function handleLogout() {
    setAppStatus(APP_STATUSES.LOADING);

    setTimeout(() => {
      logout();
    }, 1000);
  }

  if (appStatus === APP_STATUSES.LOADING) {
    return <Loading />;
  }

  return (
    <div style={{
      padding: 10, display: "flex", alignItems: "center", flexWrap: "wrap",
    }}
    >
      <small style={{
        paddingTop: 2,
      }}
      >
        You are logged in as
        { userState.email }
      </small>
      <button
        className="btn"
        style={{
          fontSize: 11, height: 24,
        }}
        onClick={handleLogout}
      >
        LOGOUT
      </button>
    </div>
  );
}

function AuthForm() {
  const { userState, setUserState, actions } = useContext(UserContext);
  // TODO: isAuthenticated should be placed somewhere else
  const isAuthenticated = !!(userState.credential && userState.credential.accessToken);

  const [appStatus, setAppStatus] = useState(APP_STATUSES.INITIAL);

  const [form, setForm] = useState({
    email: "", password: "",
  });
  const { email, password } = form;
  const setEmail = (value) => setForm({
    ...form, email: value,
  });
  const setPassword = (value) => setForm({
    ...form, password: value,
  });

  const sendForm = (event) => {
    event.preventDefault();
    setAppStatus(APP_STATUSES.LOADING);
    setTimeout(() => {
      actions.login(email, password);
    }, 1000);
  };
  useEffect(() => {
    if (isAuthenticated) {
      setAppStatus(APP_STATUSES.READY);
    }
  }, [isAuthenticated]);

  if (appStatus === APP_STATUSES.LOADING) {
    return <Loading />;
  }

  if (isAuthenticated) {
    return <AccountInfo userState={userState} logout={actions.logout} />;
  }

  const goToRegister = () => window.location.assign("https://datastore.simplefocus.tech/register?src=threethings-web");

  return (
    <div className="auth-form">
      <p>login to sync your tasks</p>
      <form onSubmit={sendForm}>
        <input
          className="form-input"
          type="email"
          autoComplete="username"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="email"
        />
        <input
          className="form-input"
          type="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder="password"
        />
        <input className="btn" type="submit" value="Login" />
        <input className="btn register" onClick={goToRegister} value="Register" />
      </form>

      <p style={{
        flex: 1, flexWrap: "wrap",
      }}
      >
        { isAuthenticated }
      </p>
    </div>
  );
}

export default AuthForm;
