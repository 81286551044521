export function removeItemWithSlice(arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

// TODO: move debounce to sft-lib, apply at model level
export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this; const
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
