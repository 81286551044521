import React from "react";
import packageJson from "../../package.json";
import GearIcon from "./icons/Gear";
import Misc from "./Misc";
import SettingItem from "./SettingItem";
import AuthForm from "./AuthForm";
import config from "../js/config";

function SidePanel({ settings, setSettings, toggleSidePanel }) {
  const setSettingItem = (key, value) => {
    setSettings({
      ...settings, [key]: value,
    });
  };
  return (
    <div id="side-panel">
      <header className="side-panel__header">
        <div className="side-panel-header">
          <div className="gear-icon-wrapper">
            <GearIcon />
          </div>
          <span>Settings</span>
        </div>
        <span onClick={toggleSidePanel} className="close-icon">×</span>
      </header>
      <hr />

      { config.SHOW_AUTH && (
      <>
        <AuthForm />
        <hr />
      </>
      )}

      <main>
        {
        config.TOGGLEABLE_FEATURES.map((key) => <SettingItem value={settings[key] || false} key={key} settingKey={key} setSettingItem={(newVal) => setSettingItem(key, newVal)} />)
}
      </main>
      <hr />

      <div className="misc">
        <Misc />
      </div>
      <footer className="version">{packageJson.version}</footer>
    </div>
  );
}

export default SidePanel;
