{
  "name": "three-things",
  "version": "1.20.0",
  "description": "",
  "scripts": {
    "start": "parcel -p 8080 src/index.html",
    "build": "parcel build src/index.html && cp dist/index.html dist/200.html && cp src/public/* dist/",
    "lint": "eslint --ext .js,.jsx ./src",
    "lint:fix": "eslint --ext .js,.jsx ./src --fix"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/nazreen/three-things.git"
  },
  "keywords": [],
  "author": "",
  "license": "ISC",
  "bugs": {
    "url": "https://github.com/nazreen/three-things/issues"
  },
  "homepage": "https://github.com/nazreen/three-things#readme",
  "dependencies": {
    "css": "^3.0.0",
    "js": "file:src/sft-lib/js",
    "prop-types": "^15.7.2",
    "react": "^16.14.0",
    "react-dom": "^16.14.0",
    "react-spring": "^8.0.27",
    "styled-components": "^5.2.0",
    "use-persisted-reducer": "^2.1.0"
  },
  "devDependencies": {
    "@parcel/transformer-image": "^2.3.2",
    "cssnano": "^5.0.1",
    "eslint": "^8.29.0",
    "eslint-config-airbnb": "^19.0.4",
    "eslint-plugin-import": "^2.26.0",
    "eslint-plugin-jsx-a11y": "^6.6.1",
    "eslint-plugin-react": "^7.31.11",
    "eslint-plugin-react-hooks": "^4.6.0",
    "parcel": "^2.3.2",
    "postcss": "^8.4.12",
    "process": "^0.11.10"
  }
}
