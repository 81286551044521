import React from "react";

function Misc() {
  return (
    <>
      <section id="about">
        <h2 className="title is-5">About</h2>
        <small>Three Things is a super simple todo app that helps you focus better.</small>
      </section>
      <section className="buy-me-coffee">
        <a href="https://ko-fi.com/Q5Q51VWYH" target="_blank" rel="noreferrer">
          <img
            height="36"
            style={{
              border: 0, height: 36,
            }}
            src="https://cdn.ko-fi.com/cdn/kofi1.png?v=2"
            alt="Buy Me a Coffee at ko-fi.com"
          />
        </a>
      </section>
    </>
  );
}

export default Misc;
