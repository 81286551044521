import {
  useEffect, useState,
} from "react";
import {
  TASK_ACTIONS, tasksReducer, tasksReducerInitialState, usePersistedTasksReducer,
} from "./tasks";
import {
  SETTINGS, VIEWING_MODES,
} from "../constants";
import buildHookUtils from "../../sft-lib/react/hook-utils";

const hookUtils = buildHookUtils({
  useEffect, useState,
});

const { usePersistedState } = hookUtils;

export { TASK_ACTIONS, tasksReducer, tasksReducerInitialState, usePersistedTasksReducer, usePersistedState };

export const appInitialState = {
  currentTabId: "1",
  viewingMode: VIEWING_MODES.DEFAULT,
  taskInFocusTitle: "",
};

export const ephemeralInitialState = {
  showSidePanel: false,
};

export const settingsInitialState = {
  [SETTINGS.MULTIPLE_TABS.KEY]: SETTINGS.MULTIPLE_TABS.VALUES.FALSE,
};
