import firebase from './firebase';

function parseFirebaseAuthResult(authResult) {
  return {
    email: authResult.user.email,
    id: authResult.user.uid,
    credential: {
      accessToken: authResult.user.ya || authResult.user.za,
      refreshToken: authResult.user.refreshToken,
    },
  };
}

const user = {
  login(email, password, cb) {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((authResult) => {
        const data = parseFirebaseAuthResult(authResult);
        cb(data);
        // authResult.user
        //   .updateProfile({
        //     displayName: this.form.name
        //   })
        //   .then(() => {});
      });
  },
  register: (email, password, cb) => {
    return new Promise((res,rej) => {
      firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((data) => {
        if (cb) {
          cb(data);
        }
        return res(data)
        // data.user
        //   .updateProfile({
        //     displayName: name
        //   })
        //   .then(() => {});
      })
      .catch((e) => {
        rej(e);
      });
    });
  },
};

export { user };

export default { user };
