import React, {
  useEffect, useRef, useState,
} from "react";
import { user } from "js/auth";
import TabsContainer from "./TabsContainer";
import TasksContainer from "./TasksContainer";
import CompletedContainer from "./Completed/Container";
import {
  appInitialState, TASK_ACTIONS, tasksReducer, tasksReducerInitialState, usePersistedState, usePersistedTasksReducer, settingsInitialState, ephemeralInitialState,
} from "../js/state";
import ConfettiCannon from "./ConfettiCannon";
import {
  SETTINGS, VIEWING_MODES, APP_STATUSES,
} from "../js/constants";
import ErrorBoundary from "./ErrorBoundary";
import FocusedView from "./FocusedView";
import Navbar from "./Navbar";
import SidePanel from "./SidePanel";
import model from "../js/model";
import config from "../js/config";
import Loading from "./Loading";
import Footer from "./Footer";

const ids = ["1", "2", "3"];

const UserContext = React.createContext(null);


function App() {
  const [appStatus, setAppStatus] = useState(APP_STATUSES.INITIAL);
  const [completedState, setCompleted] = usePersistedState("completed", []);
  const [completedCounter, setCompletedCounter] = usePersistedState(
    "completedCounter",
    0,
  );
  const [appState, setAppState] = usePersistedState("app", appInitialState);
  const [settings, setSettings] = usePersistedState("settings", settingsInitialState);
  const [tasksState, dispatchTasks] = usePersistedTasksReducer(tasksReducer, tasksReducerInitialState);
  const [ephemeralState, setEphemeralState] = useState(ephemeralInitialState);
  const { currentTabId } = appState;
  const tasks = tasksState;
  const currentTab = tasks[currentTabId];

  const confettiAnchorRef = useRef();

  const tabsObjEntries = Object.entries(tasksState).filter((x) => ["1", "2", "3"].includes(x[0])); // the filter makes sure isEmpty property is not included

  const colors = ["blue", "red", "yellow", "purple"];

  // TODO: use reducer instead
  const userInitialState = {
  };
  const [userState, setUserState] = usePersistedState("user", userInitialState);
  const userActions = {
    login: (email, password) => {
      const cb = async (data) => {
        setUserState(data);
        const res = await model.getOwn();
        dispatchTasks({
          type: TASK_ACTIONS.LOAD_TASKS, payload: res,
        });
      };
      if (tasksState.isEmpty) {
        user.login(email, password, cb);
      } else {
        alert("Make sure all tasks are empty before logging in.");
      }
    },
    logout: () => {
      dispatchTasks({
        type: TASK_ACTIONS.LOGOUT_TASKS,
      });
      setUserState(userInitialState);
    },
  };
  const forUserContext = {
    userState, setUserState, actions: userActions,
  };

  if (config.LOAD_TASKS) {
    useEffect(() => {
      async function init() {
        try {
          setAppStatus(APP_STATUSES.LOADING);
          const res = await model.getOwn(); // TODO: replace with actual, which is get document with user id
          dispatchTasks({
            type: TASK_ACTIONS.LOAD_TASKS, payload: res,
          });
          setAppStatus(APP_STATUSES.READY);
          if (config.LISTEN_TASKS) {
            model.listenOwn((doc) => dispatchTasks({
              type: TASK_ACTIONS.LOAD_TASKS, payload: doc,
            }));
          }
        } catch (e) {
          console.error(e.message);
          setAppStatus(APP_STATUSES.ERROR);
        }
      }
      init();
    }, []);
  }

  const complete = ({ tabId, id }) => {
    setAppState({
      ...appState, celebrate: true,
    });
    setTimeout(() => {
      setAppState({
        ...appState, celebrate: false,
      });
    }, 500);
    // setTimeout(() => {
    const nextId = completedCounter + 1;
    setCompletedCounter(nextId);
    const completedTask = currentTab.tasks[id];
    setCompleted([...completedState, {
      id: nextId, title: completedTask, completed_at: Date.now(),
    }]);
    dispatchTasks({
      type: TASK_ACTIONS.COMPLETE_TASK,
      payload: {
        tabId,
        task: {
          id,
        },
      },
    });
  };

  const setViewingMode = (mode) => setAppState({
    ...appState, viewingMode: mode,
  });

  const toggleViewingMode = () => {
    if (appState.viewingMode === VIEWING_MODES.DEFAULT) {
      setViewingMode(VIEWING_MODES.EVERYTHING);
    } else {
      setViewingMode(VIEWING_MODES.DEFAULT);
    }
  };

  const exitFocusViewingMode = () => setViewingMode(VIEWING_MODES.DEFAULT);
  const enterFocus = (title) => setAppState({
    ...appState, taskInFocusTitle: title, viewingMode: VIEWING_MODES.FOCUS,
  });

  const toggleSidePanel = () => setEphemeralState({
    ...ephemeralState, showSidePanel: !ephemeralState.showSidePanel,
  });

  function moveTaskUp(tabId, taskId) {
    dispatchTasks({
      type: TASK_ACTIONS.MOVE_TASK_UP,
      payload: {
        tabId, taskId,
      },
    });
  }

  function moveTaskDown(tabId, taskId) {
    dispatchTasks({
      type: TASK_ACTIONS.MOVE_TASK_DOWN,
      payload: {
        tabId, taskId,
      },
    });
  }

  if (appStatus === APP_STATUSES.LOADING) {
    return <Loading withContainer />;
  }

  return (
    <>
      <Navbar toggleSidePanel={toggleSidePanel} toggleViewingMode={toggleViewingMode} />
      <UserContext.Provider value={forUserContext}>
        {ephemeralState.showSidePanel && <SidePanel settings={settings} setSettings={setSettings} toggleSidePanel={toggleSidePanel} />}
      </UserContext.Provider>
      <main id="main-wrapper">
        <section id="tabs">
          <ErrorBoundary name="Tabs">
            <TabsContainer tabsObjEntries={tabsObjEntries} currentTabId={currentTabId} dispatchTasks={dispatchTasks} appState={appState} setAppState={setAppState} viewingMode={appState.viewingMode} multipleTabs={settings[SETTINGS.MULTIPLE_TABS.KEY]} />
          </ErrorBoundary>
        </section>
        <section id="tasks-wrapper" ref={confettiAnchorRef}>
          <ErrorBoundary name="Tasks">
            <TasksContainer ids={ids} dispatchTasks={dispatchTasks} tasks={tasks} complete={complete} viewingMode={appState.viewingMode} currentTabId={currentTabId} setTaskInFocusTitle={enterFocus} reorderTasks={settings[SETTINGS.REORDER_TASKS.KEY]} moveTaskUp={moveTaskUp} moveTaskDown={moveTaskDown} />
          </ErrorBoundary>
        </section>
        <section id="completed-list">
          <ErrorBoundary name="Completed">
            <CompletedContainer completedState={completedState} setCompleted={setCompleted} />
          </ErrorBoundary>
        </section>
        {appState.celebrate && (
          <ConfettiCannon
            anchorRef={confettiAnchorRef}
            colors={colors}
            dotCount={50}
          />
        )}
      </main>
      <Footer />
      <ErrorBoundary name="FocusView">
        {appState.viewingMode === VIEWING_MODES.FOCUS && <FocusedView exitFocusViewingMode={exitFocusViewingMode} title={appState.taskInFocusTitle} />}
      </ErrorBoundary>
    </>
  );
}

export { UserContext };

export default <App />;
