export const VIEWING_MODES = {
  DEFAULT: "DEFAULT",
  EVERYTHING: "EVERYTHING",
  FOCUS: "FOCUS",
};

export const SETTINGS = {
  MULTIPLE_TABS: {
    KEY: "multipleTabs",
    VALUES: {
      TRUE: true,
      FALSE: false,
    },
  },
  REORDER_TASKS: {
    KEY: "reorderTasks",
    VALUES: {
      TRUE: true,
      FALSE: false,
    },
  },
};

export const APP_STATUSES = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  READY: "READY",
  ERROR: "ERROR",
};
