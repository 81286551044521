import React from "react";

function FocusedView({ title, exitFocusViewingMode }) {
  return (
    <div className="focused-view">

      <h1 className="title is-1">{ title }</h1>
      <p onClick={exitFocusViewingMode}>exit focus mode</p>

    </div>
  );
}

export default FocusedView;
