import React from "react";

function Footer() {
  return (
    <footer className="app-footer">
      <section id="contact">
        For suggestions or feedback, tweet me at
        <a href="https://twitter.com/codebynazreen">@codebynazreen</a>
        .
      </section>
      {/* 15-Feb-2022 : commented out year to focus on feedback */}
      {/* ©
      {" "}
      {new Date().getFullYear()}
      {" "}
      Three Things is a
      {" "}
      <a href="https://simplefocus.tech">Simple Focus Tech</a>
      {" "}
      tool developed by nazreen
      {" "} */}
    </footer>
  );
}

export default Footer;
