import React, { useState } from "react";

function Tab({ id, title, isCurrentTab, setCurrentTab, renameTab }) {
  const [editMode, setEditMode] = useState(false);
  if (editMode) {
    return (
      <input
        type="text"
        className="tab-title"
        value={title}
        onChange={(e) => renameTab({
          tabId: id, tabTitle: e.target.value,
        })}
        onBlur={() => setEditMode(false)}
        onKeyPress={(e) => e.key === "Enter" && setEditMode(false)}
        onDoubleClick={() => setEditMode(false)}
      />
    );
  }

  return (
    <span
      className="tab-title"
      style={{
        textDecoration: isCurrentTab ? "underline" : "none", fontWeight: 500, color: isCurrentTab ? "black" : "darkgrey",
      }}
      onClick={() => setCurrentTab(id)}
      onDoubleClick={() => setEditMode(true)}
    >
      { title }
    </span>
  );
}

function Tabs({ tabsObjEntries, currentTabId, setCurrentTab, renameTab }) {
  return (
    <>
      {" "}
      { tabsObjEntries.map(([id, tab]) => {
        const isCurrentTab = id == currentTabId;
        const { title } = tab;

        return <Tab id={id} title={title} isCurrentTab={isCurrentTab} setCurrentTab={setCurrentTab} key={id} renameTab={renameTab} />;
      })}
      {" "}

    </>
  );
}

export default Tabs;
