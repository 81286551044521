import React from "react";
import Tabs from "./Tabs";
import { TASK_ACTIONS } from "../js/state/tasks";
import {
  SETTINGS, VIEWING_MODES,
} from "../js/constants";

function TabsContainer({ appState, setAppState, tabsObjEntries, currentTabId, dispatchTasks, viewingMode, multipleTabs }) {
  const renameTab = ({ tabId, tabTitle }) => {
    dispatchTasks({
      type: TASK_ACTIONS.RENAME_TAB,
      payload: {
        tabId, tabTitle,
      },
    });
  };

  const setCurrentTab = (tabId) => {
    setAppState({
      ...appState, currentTabId: tabId,
    });
  };

  const idToIndexMap = {
    1: 0,
    2: 1,
    3: 2,
  };

  if (multipleTabs === SETTINGS.MULTIPLE_TABS.VALUES.FALSE) {
    tabsObjEntries = [tabsObjEntries[idToIndexMap[currentTabId]]];
  }
  if (viewingMode !== VIEWING_MODES.EVERYTHING) {
    return (
      <Tabs tabsObjEntries={tabsObjEntries} currentTabId={currentTabId} setCurrentTab={setCurrentTab} renameTab={renameTab} />
    );
  }

  return null;
}

export default TabsContainer;
