import React from "react";
import ConfettiDot from "./ConfettiDot";

const randomInRange = (min, max) => Math.random() * (max - min) + min;

const randomIntInRange = (min, max) => Math.floor(randomInRange(min, max));

function ConfettiCannon({ anchorRef, colors, dotCount }) {
  return (
    <>
      {new Array(dotCount).fill().map((_, index) => (
        <ConfettiDot
          key={index}
          anchorRef={anchorRef}
          color={colors[randomIntInRange(0, colors.length)]}
          initialHorizontal={randomInRange(-250, 250)}
          initialUpwards={randomInRange(200, 700)}
          rotate={randomInRange(0, 360)}
          size={randomInRange(8, 12)}
        />
      ))}
    </>
  );
}

export default ConfettiCannon;
