import React from "react";
import sunriseIcon from "url:~/src/sft-lib/icons/sunrise.png";
import CompletedList from "./List";

function Completed({ completedState, removeCompletedTask }) {
  const isEmpty = completedState.length === 0;
  return (
    <div className="completed-wrapper">
      <div id="react-completed-list_contents">
        {
          isEmpty
            ? <EmptyCompleted />
            : (
              <CompletedList
                completed={completedState}
                removeCompletedTask={removeCompletedTask}
              />
            )
        }

      </div>
    </div>
  );
}

function EmptyCompleted() {
  return (
    <div className="empty-completed">
      <img src={sunriseIcon} />
      <p className="has-text-centered">New day, let's go!</p>
    </div>
  );
}

export default Completed;
