import React from "react";
import { SETTINGS } from "../js/constants";
import Switch from "./Switch";

const keyDisplayMap = {
  [SETTINGS.MULTIPLE_TABS.KEY]: "Multiple Tabs",
  [SETTINGS.REORDER_TASKS.KEY]: "Reorder Tasks",
};

function SettingItem({ settingKey, value, setSettingItem }) {
  return (
    <div className="setting-item">
      <span>
        {keyDisplayMap[settingKey]}
        :
        {" "}
        {value}
      </span>
      <Switch noText label={settingKey} checked={value} onToggle={() => setSettingItem(!value)} />
    </div>
  );
}

export default SettingItem;
