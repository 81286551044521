import React, { useState } from "react";
import PropTypes from "prop-types";
import Task from "./Task";
import { VIEWING_MODES } from "../js/constants";

// eslint-disable-next-line max-len
function Tasks({ tabId, ids, setTask, complete, tasks, viewingMode, tabTitle = "", reorderTasks, moveTaskUp, moveTaskDown }) {
  const [inputFocus, setInputFocus] = useState(null);
  return (
    <>
      { viewingMode === VIEWING_MODES.EVERYTHING && <h2 className="subtitle has-text-centered">{tabTitle}</h2>}
      {ids.map((id) => (
        <Task
          id={id}
          key={id}
          setTask={setTask}
          complete={complete}
          val={tasks[id]}
          tabId={tabId}
          inputFocus={String(inputFocus) === id}
          focusOnNext={() => id < 3 && setInputFocus(Number(id) + 1)}
          resetFocus={() => setInputFocus(null)}
          reorderTasks={reorderTasks}
          moveTaskUp={moveTaskUp}
          moveTaskDown={moveTaskDown}
        />
      ))}
    </>
  );
}

Tasks.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
};
Tasks.defaultProps = {
  ids: [],
};

export default Tasks;
