import copy from '../json/copy.json'; // Note: changed to using import on 26 Sep 21. Previously, used require.
import config from './config';

export const generateFooterText = (appName) => copy.footer.replace("%APP_NAME", appName)

// default to one second
export function debounce(func, wait = 1000, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

/**
 * 
 * @param {string} msg 
 * @param {LOG_LEVELS} logLevel 
 * @param {boolean} override 
 */
export function log(msg, logLevel, override) {
	// to ignore log levelling, pass true in override
	if (config.LOG_LEVEL >= logLevel || override) {
		console.log(msg)
	}
};