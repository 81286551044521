export const COLLECTIONS = { THREE_THINGS: 'three-things', SCRATCHPAD: 'scratchpad', SYNCSTREAM: 'syncstream' };
export const APP_NAMES = COLLECTIONS;

export const APP_INFO = {
  [COLLECTIONS.SCRATCHPAD]: {
    displayName: 'Scratchpad'
  },
  [APP_NAMES.THREE_THINGS]: {
    displayName: 'Three Things'
  },
  [APP_NAMES.SYNCSTREAM]: {
    displayName: 'SyncStream'
  }
}

export const APP_STATES = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  READY: 'READY',
  ERROR: 'ERROR'
};

export const LOG_LEVELS = {
  OFF: -1,
  ERROR: 0,
  WARN: 1,
  INFO: 2,
  DEBUG: 3
};
