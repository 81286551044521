import React from "react";
import "./Loading.css";

function Loading({ withContainer }) {
  return (
    <div id={withContainer ? "container" : ""}>
      <div id="outer">
        <div id="middle">

          <div id="inner" />
        </div>
      </div>
    </div>
  );
}

export default Loading;
